import React from 'react'
import Layout from '../components/layout/layout'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Oops.</h1>
      <p>Deze pagina bestaat niet.</p>
    </div>
  </Layout>
)

export default NotFoundPage
